import React, { useEffect } from 'react'
import { Layout } from "../../components/layout"
import { Offerings } from '../../components/offering-section'
import { Section } from '../../components/section'
import { useLanguage, passLanguage } from '../../components/language-context'
import { Row, RowText, Spacer, RowLeft, RowRight, smoothScrollTo } from '../../components/offering-common-components';

import analyze1DesktopImage from '../../images/offering/analyze/desktop/analyze-1@2x.png'
import analyze2DesktopImage from '../../images/offering/analyze/desktop/analyze-2@2x.png'
import analyze3DesktopImage from '../../images/offering/analyze/desktop/analyze-3@2x.png'
import analyze4DesktopImage from '../../images/offering/analyze/desktop/analyze-4@2x.png'
import analyze5DesktopImage from '../../images/offering/analyze/desktop/analyze-5@2x.png'
import analyze6DesktopImage from '../../images/offering/analyze/desktop/analyze-6@2x.png'

import analyze1MobileImage from '../../images/offering/analyze/mobile/analyze-1.png'
import analyze2MobileImage from '../../images/offering/analyze/mobile/analyze-2.png'
import analyze3MobileImage from '../../images/offering/analyze/mobile/analyze-3.png'
import analyze4MobileImage from '../../images/offering/analyze/mobile/analyze-4.png'
import analyze5MobileImage from '../../images/offering/analyze/mobile/analyze-5.png'
import analyze6MobileImage from '../../images/offering/analyze/mobile/analyze-6.png'

const imagesDesktop = [analyze1DesktopImage, analyze2DesktopImage, analyze3DesktopImage, analyze4DesktopImage, analyze5DesktopImage, analyze6DesktopImage];
const imagesMobile = [analyze1MobileImage, analyze2MobileImage, analyze3MobileImage, analyze4MobileImage, analyze5MobileImage, analyze6MobileImage];


const IndexPageBase = useLanguage(({location, msg, lang}) => {

  const paddingMainSection = {
    mobile: "0px 0px 10px 0px"
  }

  const sectionHeight = {
    mobile: '58px',
    desktop: '122px'
  }

  const marginFirstRow = {
    mobile: "15px 0 10px 0"
  }

  useEffect(() => {
    smoothScrollTo("#analyze-section");
  }, [])

  return (
    <Layout location={location}>
      <Section title={msg('home-offering')} lang={lang}>
        <Offerings page='analyze' />
      </Section>
      <Section title={msg('analyze-title')} showTitleLine={false} showLine={true} lineColor="#38b379" titleHeight={sectionHeight} lang={lang} id="analyze-section">
        <Row margin="0">
          <RowText>
          { msg('analyze-text') }
          </RowText>
        </Row>
      </Section>
      <Section padding={paddingMainSection}>
        <RowRight title={msg('analyze-1-title')} imageIndex={0} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile} margin={marginFirstRow}>
          { msg('analyze-1-text') }
        </RowRight>

        <RowLeft title={msg('analyze-2-title')} imageIndex={1} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('analyze-2-text') }
        </RowLeft>

        <RowRight title={msg('analyze-3-title')} imageIndex={2} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('analyze-3-text') }
        </RowRight>

        <RowLeft title={msg('analyze-4-title')} imageIndex={3} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('analyze-4-text') }
        </RowLeft>

        <RowRight title={msg('analyze-5-title')} imageIndex={4} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('analyze-5-text') }
        </RowRight>

        <RowLeft title={msg('analyze-6-title')} imageIndex={5} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('analyze-6-text') }
        </RowLeft>
      </Section>
      <Spacer />
    </Layout> 
  )}
);

const IndexPage = passLanguage(IndexPageBase)

export default IndexPage

