import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useLanguage, langPrefix } from './language-context'
import { AnalyticsService, GACategories } from '../services/analytics-service';

import measure from '../images/offering/home/offering_1@2x.png'
import analyze from'../images/offering/home/offering_2@2x.png'
import deliver from '../images/offering/home/Cleaning.jpg'
import { media } from '../styles/media-query-template'

const OfferingsLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  ${media.large`
    flex-direction: row;
    margin-top: 70px;
  `}
`

const OfferingLayout = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.large`
    margin-bottom: 0px;
  `}
`

const OfferingImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: ${props => props.height ? props.height : '240px'};
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  ${media.large`
    height: 240px;
    margin-top: 0;
  `}
`

const OfferingImage = styled.img`
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5);
`;

const OfferingButton = styled(Link)`
  line-height: 3.125em;
  font-size: 1.125em;
  font-weight: bold;
  color: #000000;
  border: 1px solid #1f2d54;
  padding: 0 20px;
  border-radius: 2px;
  margin-top: 26px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
`;

const Events = {
  home: {
    measure: {
      category: GACategories.HomePage,
      action: 'Instantly Measure',
      label: 'Instantly Measure',
    },
    analyze: {
      category: GACategories.HomePage,
      action: 'Identify Trends and Disruptions',
      label: 'Identify Trends and Disruptions',
    },
    deliver: {
      category: GACategories.HomePage,
      action: 'Take Action and Optimize Operations',
      label: 'Take Action and Optimize Operations',
    }
  },
  measure: {
    measure: {
      category: GACategories.MeasureCustomerFeedback,
      action: 'Our Offering Button Clicked',
      label: 'Instantly Measure',
    },
    analyze: {
      category: GACategories.MeasureCustomerFeedback,
      action: 'Our Offering Button Clicked',
      label: 'Identify Trends and Disruptions',
    },
    deliver: {
      category: GACategories.MeasureCustomerFeedback,
      action: 'Our Offering Button Clicked',
      label: 'Take Action and Optimize Operations',
    }
  },
  analyze: {
    measure: {
      category: GACategories.AnalyzeFeedback,
      action: 'Our Offering Button Clicked',
      label: 'Instantly Measure',
    },
    analyze: {
      category: GACategories.AnalyzeFeedback,
      action: 'Our Offering Button Clicked',
      label: 'Analyze Feedback',
    },
    deliver: {
      category: GACategories.AnalyzeFeedback,
      action: 'Our Offering Button Clicked',
      label: 'Take Action and Optimize Operations',
    }
  },
  deliver: {
    measure: {
      category: GACategories.DeliverCXImpact,
      action: 'Our Offering Button Clicked',
      label: 'Instantly Measure',
    },
    analyze: {
      category: GACategories.DeliverCXImpact,
      action: 'Our Offering Button Clicked',
      label: 'Analyze Feedback',
    },
    deliver: {
      category: GACategories.DeliverCXImpact,
      action: 'Our Offering Button Clicked',
      label: 'Take Action and Optimize Operations',
    }
  }
}

const Offering = ({text, link, src, height, width, page, offering}) => {
  let activeStyle = { backgroundColor: '#1f2d54', color: '#ffffff' }
  return (
    <OfferingLayout>
      <OfferingImageWrapper height={height} width={width}>
        <OfferingImage src={src} />
      </OfferingImageWrapper>
      <OfferingButton activeStyle={activeStyle} to={link}
        onClick={AnalyticsService.trackCustomEvent}
        data-ec={Events[page][offering].category}
        data-ea={Events[page][offering].action}
        data-el={Events[page][offering].label}>{text}</OfferingButton>
    </OfferingLayout>
  )
}

const Offerings = useLanguage(({lang, msg, page}) => {
  return (
    <OfferingsLayout>
      <Offering src={measure} text={msg('offering-measure')} link={langPrefix(lang, '/offering/measure')} width="320px" page={page} offering='measure' />
      <Offering src={analyze} text={msg('offering-analyze')} link={langPrefix(lang, '/offering/analyze')} width="320px" page={page} offering='analyze' />
      <Offering src={deliver} text={msg('offering-deliver')} link={langPrefix(lang, '/offering/deliver')} width="320px" page={page} offering='deliver' />
    </OfferingsLayout>
  )
})


export { Offerings }
